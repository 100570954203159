import { ShoppingCart } from "@mui/icons-material";
import { Container } from "@mui/material";
import React from "react";

function CartCounter({ companyPrimaryColor, companySecondaryColor, cart }) {
  return (
    <Container
      className="fixed h-11 bottom-4 mx-auto inset-x-0 rounded max-w-sm flex justify-center gap-4 items-center cursor-pointer"
      sx={{
        backgroundColor: companyPrimaryColor,
        color: companySecondaryColor,
        border:`2px solid ${companyPrimaryColor}`,
        width:"fit-content",
        display:"flex", 
        "&:hover": {
            color: companyPrimaryColor,
            backgroundColor: companySecondaryColor,
          }, 
      }}
    >
      {
        <>
          <ShoppingCart />
          {"    "} {cart}
          {"    "}{cart=== 1?` item in your cart`:` items in your cart`}
        </>
      }
    </Container>
  );
}

export default CartCounter;
