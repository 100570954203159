import React, { useEffect, useState } from "react";
import ItemCard from "../ItemCard";
import { Container } from "@mui/material";
import ItemPopup from "../Widgets/ItemPopup";
import Login from "./Login";
import { useSelector } from "react-redux";

function MenuTab({
  items,
  image,
  primaryColor,
  secondaryColor,
  isLoading,
  services,
}) {
  const [isItemPopUpVisible, setIsItemPopupVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All");

  const [isLoginVisible, setIsLoginVisible] = useState(false);

  const [isLoginSuccess, setIsLoginSuccess] = useState(false);

  const isFirstTime = useSelector((state) => state.isFirstTime);

  // console.log(items);
  useEffect(() => {
    // if(items.length){
    //   items
    // }
  }, []);
  return (
    <>
      {isLoginVisible ? (
        <Login
          isFirstTime={isFirstTime}
          companyLogo={image}
          handleOnClose={(success) => {
            if (success) {
              setIsLoginSuccess(true);
            }
            setIsLoginVisible(false);
          }}
        />
      ) : null}
      {isItemPopUpVisible && selectedItem && (
        <ItemPopup
          isOpen={isItemPopUpVisible}
          item={selectedItem}
          handleOnClose={() => setIsItemPopupVisible(false)}
          handleGoToLogin={() => setIsLoginVisible(true)}
          isLoading={isLoading}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          services={services}
        />
      )}
      <Container
        sx={{
          // display: "flex",
          // paddingTop: "10.5rem",
        }}
      >
        <div className="flex pt-4 menu-tab-body mb-20">
          <div
            className=" side-menu-bar p-4 gap-4 w-fit h-96 mr-8  text-center flex flex-col rounded-lg mx-1"
            style={
              {
                // boxShadow:"0px 0px 5px black",
                // width:"calc(100% - 8px)"
              }
            }
          >
            <h1 className="font-bold  mb-4 categories-title">CATEGORIES</h1>
            <Container
              className={
                "All" == selectedCategory
                  ? " p-2 my-2 cursor-pointer text-sm rounded w-40 bg-orange-400 text-white side-menu-tab"
                  : "p-2 my-2 cursor-pointer text-sm side-menu-tab rounded"
              }
              sx={{
                whiteSpace:"nowrap",
                backgroundColor:
                  "All" == selectedCategory ? primaryColor : "transparent",
                color: "All" == selectedCategory ? secondaryColor : "black",
                boxShadow: "All" == selectedCategory ? "0 0 5px gray" : null,
                border:
                  "All" == selectedCategory
                    ? `2px solid ${primaryColor}`
                    : `2px solid white`,

                "&:hover": {
                  border: `2px solid ${primaryColor}`,
                  background: secondaryColor,
                  color: primaryColor,
                },
              }}
              onClick={() => setSelectedCategory("All")}
            >
              {"All"}
            </Container>
            {[
              ...new Set(
                items.map((e) => {
                  return e.category_id;
                })
              ),
            ].map((category) => (
              <Container
                className={
                  items.filter((item) => item.category_id == category)[0]
                    .category_name == selectedCategory
                    ? " p-2 my-2 cursor-pointer text-sm rounded bg-orange-400  text-white side-menu-tab"
                    : "p-2 my-2 cursor-pointer text-sm side-menu-tab rounded"
                }
                sx={{
                  whiteSpace:"nowrap",
                  backgroundColor:
                    items.filter((item) => item.category_id == category)[0]
                      .category_name == selectedCategory
                      ? primaryColor
                      : "transparent",
                  color:
                    items.filter((item) => item.category_id == category)[0]
                      .category_name == selectedCategory
                      ? secondaryColor
                      : "black",
                  boxShadow:
                    items.filter((item) => item.category_id == category)[0]
                      .category_name == selectedCategory
                      ? "0 0 5px gray"
                      : null,
                  border:
                    items.filter((item) => item.category_id == category)[0]
                      .category_name == selectedCategory
                      ? `2px solid ${primaryColor}`
                      : `2px solid white`,

                  "&:hover": {
                    background: secondaryColor,
                    color: primaryColor,
                    border: `2px solid ${primaryColor}`,
                  },
                }} 
                onClick={() =>
                  setSelectedCategory(
                    items.filter((item) => item.category_id == category)[0]
                      .category_name
                  )
                }
              >
                
                {
                  items.filter((item) => item.category_id == category)[0]
                    .category_name
                } 
              </Container>
            ))}
          </div>
          <div className="menu h-fit">
            {items.length
              ? items
                  .filter((filtered) => {
                    if (selectedCategory === "All") {
                      return filtered;
                    } else {
                      return filtered.category_name === selectedCategory;
                    }
                  })
                  .map((e) => (
                    <ItemCard
                      name={e.item_name}
                      price={Math.min.apply(
                        Math,
                        e.variations.map((f) => f.price)
                      )}
                      image={e.image}
                      onClick={() => {
                        setSelectedItem(e);
                        setIsItemPopupVisible(true);
                      }}
                    />
                  ))
              : null}
          </div>
        </div>
      </Container>
    </>
  );
}

export default MenuTab;
