import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import Menu from './Scene/Menu';
import { GlobalStyles } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLogin } from "./state";
import Preloader from "./Scene/Preloader/Preloader";

function App() {
  const dispatch = useDispatch();
  const [isPreloaded, setIsPreloaded] = useState(false);

  useEffect(() => {
    // window.addEventListener("beforeunload", handleOnbeforeunload);
    handleOnbeforeunload();
  }, []);
  const handleOnbeforeunload = () => {
    // dispatch(
    //   setLogin({
    //     token: null,
    //   })
    // );
    console.log("app reloaded");
  };
  return (<>
  <GlobalStyles
          styles={{
            scrollbarColor: "#6b6b6b #2b2b2b",
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              backgroundColor: "#d3d3d3", //scroll box color
              borderRadius: 8,
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderRadius: 8,
              minHeight: 24,
              backgroundColor: "#959595", //scroll bar color

              border: "2px solid #959595",
            },
            "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
            "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              border: "1px solid #959500",
              backgroundColor: "#959595", //scroll bar color when hovered
            },
            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
              backgroundColor: "#2b2b2b",
            },
          }}
        />
    <Router>
    {!isPreloaded && (
            <Preloader
              setIsLoaded={() => {
                setIsPreloaded(true);
              }}
            />
          )}
      <Routes>
        <Route
          path="/menu"
          element={
            <Menu />
          }
        />
      </Routes>
    </Router>
</>
  );
}

export default App;
