import React from "react";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { Cancel } from "@mui/icons-material";

function CartTable({ rows, handleDeleteCart, primaryColor, secondaryColor }) {
  return (
    <div>
      <DataGrid
        getRowHeight={() => "auto"}
        draggable
        rows={rows}
        className="table"
        columns={[
          {
            field: "name",
            headerName: "Name",
            headerClassName: "super-app-theme--header",
            align: "right",
            headerAlign: "right",
            flex: 1,
            minWidth: 150,
          },

          {
            field: "quantity",
            headerName: "Quantity",
            headerClassName: "super-app-theme--header",
            width: 100,

            align: "center",
            padding: "10px",
            headerAlign: "center",
          },

          {
            field: "price",
            headerName: "Total Price",
            headerClassName: "super-app-theme--header",
            width: 100,

            align: "center",
            padding: "10px",
            headerAlign: "center",
          },
          {
            field: "action",
            headerName: "",
            headerClassName: "super-app-theme--header",
            flex: 1,
            renderCell: (params) => (
              <Cancel
                onClick={() => handleDeleteCart(params.value.id)}
                style={{
                  color: primaryColor,
                  cursor: "pointer",
                  padding: "0",
                  backgroundColor:secondaryColor,
                  borderRadius:"50%"
                }}
              />
            ),
          },
        ]}
        isRowSelectable={() => false}
        sx={{
          boxShadow: 2,
          borderRadius: 0,
          backgroundColor: " rgb(228, 237, 240, 0.3)",
          "& .MuiDataGrid-cell:hover": {
            color: "blue",
          },
          "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {},
          "& .MuiDataGrid-cell": {
            outline: " none !important;",
            padding: " 20px",
            width: "fit-content",
            alignItems: "start",
          },

          "& .MuiDataGrid": {
            borderRadius: "0",
          },
          cells: { borderRight: "1px solid red" },
          ".MuiDataGrid-cell:last-child": { borderRight: "none" },
        }}
        // initialState={{
        //     pagination: {
        //         paginationModel: { page: 0, pageSize: 10 },
        //     },
        // }}
        // pageSizeOptions={[10, 25, 50, 100]}
      />
    </div>
  );
}

export default CartTable;
