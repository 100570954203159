import React, { useEffect, useState } from "react";
import "./Reservation.css";
import { Button } from "@mui/material";
import ApiResult from "../../CommonWidgets/ApiResult";
import {
  addReservation,
  fetch_branches,
} from "../../Service/Reservation_Services";
import CustomSelector from "../../CommonWidgets/CustomSelector";
import CustomTextfield from "../../CommonWidgets/CustomTextfield";
import Loading from "../../CommonWidgets/Loading";

const Reservation = ({
  passFormData,
  primaryColor,
  secondaryColor,
  companyName,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    date: "",
    time: "",
    numberofgustes: "",
    message: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(
    "Reservation is succesfully held for you, we will contact you soon for confirmation."
  );

  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);

    console.log(formData);
    // passFormData(formData)
    await addReservation({
      branch_id: selectedBranch,
      guests: formData.numberofgustes,
      name: formData.name,
      date: formData.date,
      time: formData.time,
      message: formData.message,
      phone: formData.phone,
    })
      .then((res) => {
        setIsLoading(false);
        setIsMessageVisible(true);
        setFormData({
          name: "",
          phone: "",
          date: "",
          time: "",
          numberofgustes: "",
          message: "",
        });
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
        setErrorMsg(
          e.response?.data?.msg?.code
            ? "You have already set the specified reservation"
            : "Sorry, try again."
        );
        setIsError(true);
        setIsMessageVisible(true);
      });
  };

  const getBranches = async () => {
    await fetch_branches(getQueryVariable("company"))
      .then((res) => {
        console.log(res, 123);
        setBranches(res);
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMsg("couldn't get branches try again later");
        setIsError(true);
        setIsMessageVisible(true);
      });
  };

  useEffect(() => {
    getBranches();
  }, []);
  const inputStyle = {
    border: "none",
    // borderBottom: "1px solid gray",
    outline: "none",
  };

  const [isMessageVisible, setIsMessageVisible] = useState(false);

  return (
    <div className="reservation w-full flex justify-center items-center ">
     {isLoading? <Loading/>:null}
      {isMessageVisible ? (
        <ApiResult
          message={errorMsg}
          handleClose={() => {
            setIsMessageVisible(false);
            setErrorMsg(
              "Reservation is succesfully held for you, we will contact you soon for confirmation."
            );
            setIsError(false);
          }}
          isSuccess={!isError}
        />
      ) : null}
      <form className="form-container w-full shadow-2xl shadow-black" onSubmit={handleSubmit} style={{
        backgroundColor:primaryColor,
        color:secondaryColor,
      }}>
        <div className="flex justify-center flex-col items-center py-4">
          <h1 className="text-lg font-bold ">TABLE RESERVATION</h1>
          <p className="pb-2">Make table reservation at {companyName}.</p>
        </div>
        <div>
          <CustomTextfield
          isReservation={true}
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={(e)=>
             
              handleChange(e)
            
            }
            style={inputStyle}
            placeholder="Your name"
          />
        </div>{" "}
        <div>
          <CustomTextfield
          isReservation={true}

            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            style={inputStyle}
            placeholder="Phone"
          />
        </div>
        <div className="datetime">
          <div>
            <CustomTextfield
          isReservation={true}

              type="date"
              id="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              style={inputStyle}
              // label="Date"
            />
          </div>
          <div>
            <CustomTextfield
          isReservation={true}

              type="time"
              id="time"
              name="time"
              value={formData.time}
              onChange={handleChange}
              style={inputStyle}
            />
          </div>
        </div>
        <div>
          <CustomTextfield
          isReservation={true}

            type="number"
            id="gustes"
            name="numberofgustes"
            value={formData.numberofgustes}
            onChange={handleChange}
            style={inputStyle}
            placeholder="Number of Gustes"
          />
        </div>
        <div className="message">
          <CustomTextfield
          isReservation={true}

            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            style={inputStyle}
            placeholder="Message"
            multiline={true}
          />
        </div>
        <CustomSelector
          required={true}
          placeholder={"Select A Branch"}
          value={selectedBranch}
          onChange={(e) => {
            setSelectedBranch(e.target.value); 
          }}
          menuItems={branches}
          isReservation={true}
          secondaryColor={secondaryColor}
          primaryColor={primaryColor}
        />
        {/* <button
          type="submit"
          style={{ background: primaryColor, color: secondaryColor }}
        >
          Submit
        </button> */}
        <div className="w-full flex justify-end">
          <Button
            disabled={
              String(formData.name).trim() === "" ||
              String(formData.date).trim() === "" ||
              String(formData.time).trim() === "" ||
              String(formData.message).trim() === "" ||
              String(formData.phone).trim() === "" ||
              String(formData.numberofgustes).trim() === ""||
              !branches.length
              ||
              !selectedBranch
            }
            sx={{
              background: primaryColor,

              color: secondaryColor,
              border: `2px solid ${primaryColor}`,
              marginRight: "10px",
              paddingRight: ".8rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // gap: "5px",
              height: "fit-content",
              "&:hover": { background: secondaryColor, color: primaryColor },
              "&.Mui-disabled": {
                background: "#eaeaea !important",
                color: "#c0c0c0",
                border: "0px !important",
              },
            }}
            onClick={() => {
              handleSubmit();
            }}
          >
            <p>Submit</p>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Reservation;

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&amp;");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}
