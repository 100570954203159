import React, { useEffect, useState } from "react";
import { Button, Dialog } from "@mui/material";
import { Close } from "@mui/icons-material";
import CustomMultipleSelect from "../../CommonWidgets/CustomMultipleSelect";
import CustomTextfield from "../../CommonWidgets/CustomTextfield";
import CustomSelector from "../../CommonWidgets/CustomSelector";
import { addToCart } from "../../Service/Menu_Services";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "../../state";
import ShortNote from "../../Components/ShortNote/ShortNote";

function ItemPopup({
  item,
  handleOnClose,
  isOpen,
  handleGoToLogin,
  isLoading,
  primaryColor,
  secondaryColor,
  services
}) {
  const [price, setPrice] = useState(
    Math.min.apply(
      Math,
      item.variations.map((e) => e.price)
    )
  );
  const [quantity, setQuantity] = useState(1);
  const [variation, setVariation] = useState(
    item.variations.length
      ? item.variations.filter(
          (e) =>
            e.price ==
            Math.min.apply(
              Math,
              item.variations.map((e) => e.price)
            )
        )[0].id
      : []
  );
  const [addonPrice, setAddonPrice] = useState(0);
  const [selectedAddon, setSelectedAddon] = useState([]);
  const [specialInstruction, setSpecialIntruction] = useState("");
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log(item.variations.filter(e => e.price === Math.min.apply(Math, item.variations.map(e => e.price)))[0].name)
    // setPrice(Math.min.apply(Math, item.variations.map(e => e.price)))
    // setVariation(item.variations.filter(e => e.price === Math.min.apply(Math, item.variations.map(e => e.price)))[0].name)
  }, []);

  const handleChangeAddon = (newList) => {
    setSelectedAddon(newList);
    setAddonPrice(
      newList.map((e) => e.price).reduce((partialSum, a) => partialSum + a, 0)
    );
    console.log(
      newList.map((e) => e.price).reduce((partialSum, a) => partialSum + a, 0)
    );
  };

  const handleAddToCart = async () => {
    isLoading(true);
    // const params = { item_id:item.item_id, variation_id:variation, addon:selectedAddon.map(e=>e.id), specialInstruction, quantity}
    await addToCart(token, {
      item_id: item.item_id,
      variation_id: variation,
      addon: selectedAddon.map((e) => e.id),
      specialInstruction,
      quantity,
      branch: getQueryVariable("branch"),
    })
      .then((response) => {
        console.log(response, "response of add to cart");
        dispatch(
          setCart({
            cart: response.msg,
          })
        );
        isLoading(false);
      })
      .catch((e) => {
        isLoading(false);

        console.log(e, "error in add to cart");
      });
  };
  return (
    <Dialog
      closeOnEscape={true}
      open={isOpen}
      fullWidth
      PaperProps={{ sx: { height: "fit-content", borderRadius: "8px"}}}
      onClose={handleOnClose}
    >
      <div className="w-full flex flex-col py-4 bg-color-whites">
        <div className="pt-1 pb-4 px-4">
          <div className="flex flex-row justify-between">
            {" "}
            {item.item_name}{" "}
            <Close onClick={handleOnClose} className="cursor-pointer" />
          </div>
        </div>
        <hr className="pb-2" />
        <div className="px-4 w-full flex flex-col justify-center items-center overflow-hidden"
          style={{maxHeight:"50dvh"}}
        >
          <img className="" src={item.image}
          style={{
            maxWidth:"100%",
            maxHeight:"100%"
          }}
          ></img>
        </div>
        <div className="w-full justify-center items-center flex flex-col">
          <div className="text-2xl font-medium p-4">{item.item_name}</div>
          <div>{(price + addonPrice) * quantity} Br.</div>
          <div className="pt-4 px-4 pb-2 text-justify">{item.description}</div>
          <div className="my-4 flex font-semibold items-center select-none justify-center bg-gray-100 w-full">
            <div
              onClick={() => {
                if (quantity > 1) setQuantity(quantity - 1);
              }}
              className="bg-gray-300 rounded-full w-8 h-8 flex justify-center items-center cursor-pointer"
            >
              -{" "}
            </div>
            <div className="p-4 flex"> {quantity}</div>
            <div
              onClick={() => setQuantity(quantity + 1)}
              className="bg-gray-300 rounded-full w-8 h-8 flex justify-center items-center cursor-pointer"
            >
              +{" "}
            </div>
          </div>
        </div>
         {item.variations.length > 1 ? (<div className="px-4">
         
            <CustomSelector

              required={true}
              label={"Select Options"}
              value={variation}
              onChange={(e) => {
                console.log(e.target.value);
                setVariation(e.target.value);
                setPrice(
                  item.variations.filter((variation) => {
                    if (variation.id == e.target.value) {
                      return variation;
                    }
                  })[0].price
                );
              }}
              menuItems={item.variations.map((e) => ({
                id: e.id,
                name: e.name,
              }))}
            />
          
        </div>) : null}
        {item.addons.length ? (
          <div className="px-4 my-4 w-full">
            <CustomMultipleSelect
              fullWidth={true}
              required
              placeholder={"Select Addon"}
              selected={selectedAddon}
              options={item.addons}
              setSelected={handleChangeAddon}
            />
          </div>
        ) : null}
        <div className="text-lg font-small pb- flex w-full justify-center">
          Special Instruction
        </div>
        <div className="px-4 w-full ">
          <CustomTextfield
            multiline
            label={"Special instruction"}
            value={specialInstruction}
            onChange={(e) => {
              if (e.target.value.length < 400)
                setSpecialIntruction(e.target.value);
            }}
            //  required
          />
        </div>
        {!services || !services.length ? (
            <div className="w-full flex justify-end p-4 mobile-width">
              <ShortNote
                text={"Sorry, we are not taking any orders at the moment"}
              />
            </div>
          ) : null}
        <div className="py-4 px-4 pb-0 flex justify-end gap-2">
          <Button onClick={handleOnClose
          
          
          }
          
          sx={{
            background: primaryColor,

            color: secondaryColor,
            border:`2px solid ${primaryColor}`,
            marginRight: "10px",
            paddingRight: ".8rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // gap: "5px",
            height: "fit-content",
            "&:hover": { background: secondaryColor, color: primaryColor },
          }}>
            <p>Cancel</p>
          </Button>
          <Button
            // type="submit" 
            disabled={
              !services|| !services.length
            }
            sx={{
              background: primaryColor,

              color: secondaryColor,
              border:`2px solid ${primaryColor}`,
              marginRight: "10px",
              paddingRight: ".8rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // gap: "5px",
              height: "fit-content",
              "&:hover": { background: secondaryColor, color: primaryColor },
              "&.Mui-disabled": {
                background: "#eaeaea !important",
                color: "#c0c0c0",
                border: "0px !important",
              },
            }}
            onClick={() => {
              if (token) {
                handleAddToCart();
                handleOnClose();
              } else {
                handleGoToLogin();
              }
            }}
          >
            <p>Add to cart</p>
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default ItemPopup;

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&amp;");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}
