import { Close } from "@mui/icons-material";
import React from "react";

function Confirmation() {
  return (
    <div>
      <div className="confirmation">
        <div className="top-bar flex border-b-2 bg-slate-300 h-20 justify-start items-center gap-4 p-4">
          <Close/> 
          <p className="title-text">
            Order Tracker
          </p>
        </div>
        <div className="confirmation-top-text">
          <h1>Thank You</h1>
        </div>
        <div className="success-image"></div>
        <div className="confirmation-bottom-text">
          <p className="payment-location"></p>
          <p className="payment-code"></p>
          <p className="instruction"></p>
        </div>
      </div>
    </div>
  );
}

export default Confirmation;
