import { createSlice } from "@reduxjs/toolkit";
// rxslice
const initialState = {
  customer: null,
  token: null,
  cart: [],
  isFirstTime: true,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.customer = action.payload.customer;
      state.token = action.payload.token;
    },
    setCart: (state, action) => {
      state.cart = action.payload.cart;
    },

    setIsFirstTime: (state, action) => {
      state.isFirstTime = action.payload.isFirstTime;
    },
  },
});

export const { setLogin, setCart, setIsFirstTime } = authSlice.actions;
export default authSlice.reducer;
