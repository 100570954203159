import React from "react";
import eat from "../Assets/Images/eat.png";
import "./ItemCard.css";
function ItemCard({ name, price, image, onClick }) {
  return (
    <div className="item-card border" onClick={onClick}>
      <div
        className="w-96 my-4  image overflow-hidden"
        style={{
          backgroundImage: image ? `url(${image})` : `url(${eat})`,
          backgroundSize: "cover",
          backgroundRepeat:"no-repeat",
          backgroundPosition:"center",
          margin:"0 .5rem",
          height:"calc(100% - 1rem)",
          width:"200px",
          borderRadius:"10px",

        }}
      >
        {/* <img className="h-full w-auto " src={image ? image : eat} /> */}
      </div>
      <div
        className="text flex items-start justify-start w-full h-full flex-col px-4 py-4"
        // style={{
        //   width: "100%",
        //   display: "flex",
        //   flexDirection: "column",
        //   padding: "0 1rem",
        //   height: "80%",
        //   fontWeight: "bold",
        //   fontSize:"1.2rem",
        //   alignItems:"center",
        //   textAlign:"left"
        // }}
      >
        {" "}
        <div>
          <p className="text-xl font-semibold item-name-text">{name}</p>
          <p style={{ fontSize: ".9rem", color: "grey" }}>Br {price}</p>
        </div>
      </div>
    </div>
  );
}

export default ItemCard;
