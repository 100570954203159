export const URLs = {
    LOGIN : "/api/login/customer",
    REGISTER : "/api/register/customer",
    CHECK_PHONE:"/api/check-phone",
    SEND_OTP:"/api/send-otp",

    // PRELOAD 
    PRELOAD_STATE:"/api/preload/customer",


    ADD_TO_CART:"/api/add/cart",
    DELETE_CART:"/api/delete/cart",

    GET_ORDER_METHODS:"/api/fetch/services",
    PLACE_ORDER:"/api/place/order",

    CHAPA_PAYMENT:"/api/get/chapa-payment-link",
    VERIFY_CHAPA_PAYMENT:"/api/verify/chapa-payment",


    // RESERVATION
    ADD_RESEREVATION:"/api/add/reservation",
    FETCH_COMPANY_BRANCHS:"/api/get-company-branches",
 

    //ADMIN
    FETCH_ADMIN_DATA:"/api/fetch/admin-data",
    FETCH_COMPANIES:"/api/get-companies",
    ADD_NEW_COMPANY:"/api/register/company",
    UPDATE_COMPANY_PROFILE:"/api/update/company",
    DELETE_COMPANY:"/api/delete/company",
    FETCH_ALL_USERS:"/api/fetch/users",
    ADD_NEW_USER:"/api/register/user",
    UPDATE_USER_PROFILE:"/api/update/user",
    CHECK_USERNAME:"/api/check-username",
    
    FETCH_SERVICES:"/api/fetch/services",
    ADD_SERVICE:"/api/add/services",
    UPDATE_SERVICE:"/api/update/service",
    UPDATE_SERVICE_STATUS:"/api/update/service-status",
    DELETE_SERVICE:"/api/delete/service",


    // MANAGER

    ADD_COMPANY_BRANCH : "/api/add/branch",
    UPDATE_COMPANY_BRANCH : "/api/update/branch",

    FETCH_COMPANY_CATEGORY : "/api/fetch/categories",
    ADD_COMPANY_CATEGORY : "/api/add/category",
    UPDATE_COMPANY_CATEGORY : "/api/update/category",
    DISABLE_COMPANY_CATEGORY : "/api/disable/category",
    DELETE_COMPANY_CATEGORY : "/api/delete/category",

    GET_COMPANY_ITEMS : "/api/get/items",
    ADD_NEW_ITEM: "/api/add/item",
    DELETE_ITEM:"/api/delete/item",



    // MENU

    FETCH_ITEMS : "/api/get/menu",


}