import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

function CustomSelector({
  label,
  menuItems,
  onChange,
  value,
  required,
  isReservation,
  primaryColor,
  secondaryColor,
  placeholder,
}) {
  return (
    <div className="text-field">
      {isReservation ? (
        <InputLabel style={{color:secondaryColor}} id="demo-simple-select-label">{placeholder}</InputLabel>
      ) : null}
      <FormControl
        fullWidth
        // margin="normal"
      >
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>

        <Select
          label={label}
          // required={required}
          placeholder={isReservation ? `choose ${placeholder}` : null}
          disabled={menuItems.length ? false : true}
          // labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          onChange={onChange}
          // borderRadius={".7rem"}
          sx={{
            // "*": {  borderRadius: ".7rem",},
            backgroundColor: "white",
            // borderRadius: ".7rem",
            color:"black"
          }}
        >
          {menuItems.map((e) => (
            <MenuItem disabled={e.disabled} value={e.id}>
              {e.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default CustomSelector;
