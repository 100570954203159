import {  Close } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import React from "react";
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
function ThanksForOrdering({handleOnClose, isOpen, primaryColor, secondaryColor}) {
  return (
    <Dialog
      closeOnEscape={true}
      open={isOpen}
      fullWidth
      PaperProps={{ sx: { height: "fit-content", borderRadius: "8px" } }}
    >
      <div className="w-full flex flex-col py-4 bg-color-whites">
        <div className="pt-1 pb-4 px-4">
          <div className="flex flex-row justify-end"> 
            <Close onClick={()=>handleOnClose()} className="cursor-pointer" />
          </div>
        </div>
        <hr className="pb-2" />
        <div className="flex justify-center items-center flex-col">
        <DoneOutlineOutlinedIcon style={{
            fontSize:"10rem",
            color:primaryColor,
            background:secondaryColor,
            borderRadius:"50%",
            padding:"1rem",
            border:`5px solid ${primaryColor}`

        }}/>
        <p className="text-lg pt-8">Order placed succesfully, we will get back to you soon.</p>
        
        <p className="text-2xl p-4 font-bold ">Thank You For Using Our Service.</p>
        </div>
      </div>
    </Dialog>
  );
}

export default ThanksForOrdering;
