import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import { URLs } from "../../api/URLs";
import { useDispatch, useSelector } from "react-redux";
import { setCart, setLogin } from "../../state";

function Preloader({ setIsLoaded }) {
  //   const [isLoaded, setIsLoaded] = useState();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);

  const loadState = async () => {
    await axios
      .get(URLs.PRELOAD_STATE, {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
        withCredentials: true,
      })
      .then((res) => {
        console.log(res)
        dispatch(
          setCart({
            cart: res?.data?.cart,
          })
        );
        dispatch(
          setLogin({
            token: res?.data?.token,
            customer: res?.data?.customer
          })
        )
        setIsLoaded();
      })
      .catch((err) => {
        console.log(err, "error getting preloaded data");
        if (err.response?.status === 409 || err.response?.status === 401) {
          dispatch(
            setCart({
              cart: [],
            })
          );
          dispatch(
            setLogin({
              token: null,
              customer: null
            })
          )
        }
        // throw err;
      });
  };
  useEffect(() => {
    if (
      !getQueryVariable("order_method") &&
      token &&
      getQueryVariable("company") &&
      getQueryVariable("branch") &&
      !getQueryVariable("order_location")
    )  {
      loadState();
      console.log("token present");
    } else {
      setIsLoaded();
      console.log("no token");
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        background: "white",
        position: "fixed",
        zIndex: "99"
      }}
    >
      <img
        src="https://order.qranbessa.net/assets/img/logo/qr_text.png"
        width={"300px"}
      />
    </div>
  );
}

export default Preloader;



function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&amp;");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}
