import { URLs } from "../api/URLs.js";
import axios from "../api/axios.js";

export const addReservation = async (params) => {
  const response = await axios
    .post(URLs.ADD_RESEREVATION, JSON.stringify(params), {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
    .then((res) => {
      console.log(res?.data?.companies, "stuff");
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error getting company and branches");
      throw error;
    });
  return response;
};

export const fetch_branches = async (id) => {
  const response = await axios
    .get(URLs.FETCH_COMPANY_BRANCHS, {
      params: { id: id },
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
    .then((res) => {
      return res?.data?.msg;
    })
    .catch((error) => {
      throw error;
    });
  return response;
};
